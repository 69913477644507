import { getAuthPaths } from '~/utils/auth/auth'

/**
 * Redirects to login page when user isn't signed in
 */
export default defineNuxtRouteMiddleware(() => {
  if (!process.browser) {
    return
  }

  const userStore = useUserStore()
  const localeStore = useLocaleStore()

  if (!userStore.isLoggedIn) {
    const { login } = getAuthPaths(localeStore.locale)
    // Not with navigateTo() as that won't cause reload
    window.location = `${localeStore.urls.auth}/${login}`
  }
})
